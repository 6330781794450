import Col from "antd/lib/col";
import Row from "antd/lib/row";
import cc from "classcat";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as BulbFilled } from "../../../assets/icons/bulbFilled.svg";
import { ReactComponent as DotFilled } from "../../../assets/icons/dotFilled.svg";
import { ReactComponent as LeftOutlined } from "../../../assets/icons/leftOutlined.svg";
import { ReactComponent as RightOutlined } from "../../../assets/icons/rightOutlined.svg";
import { Auth, AuthContext } from "../../../context/AuthProvider";
import { SMOContext, SMOContextType } from "../../../context/SMOProvider";
import { marketplaceName } from "../../../utils/defaults";
import RetailAdvertiserViewLink from "../../Link/RetailAdvertiserViewLink";
import RetailText from "../../Typography/RetailText";

import cm from "./style.module.scss";

export interface RetailInfoBarProps {
  type: "create" | "campaign";
}

const RetailInfoBar = ({ type }: RetailInfoBarProps) => {
  const { t, i18n } = useTranslation();

  const { showAdminBar } = useContext(AuthContext) as Auth;

  const { showDocs } = useContext(SMOContext) as SMOContextType;

  const [visible, setVisible] = useState<number>(0);

  const dataForCreateType = [
    <RetailText weight="medium" size="xs" className={cm.text}>
      {t("components.info.firstTipCreate")}
    </RetailText>,
    <RetailText weight="medium" size="xs" className={cm.text}>
      {t("components.info.secondTipCreate")}
    </RetailText>,
    <RetailText weight="medium" size="xs" className={cm.text}>
      {t("components.info.thirdTipCreate")}
    </RetailText>,
    <RetailText weight="medium" size="xs" className={cm.text}>
      {t("components.info.forthTipCreate")}
    </RetailText>,
  ];

  const dataForCampaignTypeTr = [
    <RetailText weight="medium" size="xs" className={cm.text}>
      <b>Sponsorlu Ürün</b> reklam formatı ile ürünlerinizi ön plana çıkarabilir
      ve satışlarınızı arttırabilirsiniz. Hemen başlamak için
      <RetailAdvertiserViewLink
        to="/create-campaign"
        className={cm.link}
        state={{
          ad_type: "PRODUCT",
        }}
      >
        Yeni Kampanya Ekle!
      </RetailAdvertiserViewLink>
    </RetailText>,
    <RetailText weight="medium" size="xs" className={cm.text}>
      Platform hakkında daha fazla bilgi sahibi olmak için
      <RetailAdvertiserViewLink to="/academy" className={cm.link}>
        {marketplaceName} Reklam Rehberini
      </RetailAdvertiserViewLink>
      ziyaret edebilirsiniz.
    </RetailText>,
  ];

  const dataForCampaignTypeEn = [
    <RetailText weight="medium" size="xs" className={cm.text}>
      With the <b>Sponsored Product</b> ad format, you can highlight your
      products and increase your sales.
      <RetailAdvertiserViewLink
        to="/create-campaign"
        className={cm.link}
        state={{
          ad_type: "PRODUCT",
        }}
      >
        Add a New Campaign
      </RetailAdvertiserViewLink>
      to get started.
    </RetailText>,
    <RetailText weight="medium" size="xs" className={cm.text}>
      To learn more about the platform, you can visit
      <RetailAdvertiserViewLink to="/academy" className={cm.link}>
        {marketplaceName} Ads Guide
      </RetailAdvertiserViewLink>
    </RetailText>,
  ];

  const dataForCampaignTypeAr = [
    <RetailText weight="medium" size="xs" className={cm.text}>
      <RetailAdvertiserViewLink
        to="/create-campaign"
        className={cm.link}
        state={{
          ad_type: "PRODUCT",
        }}
      >
        قم بإضافة حملة إعلانية جديدة
      </RetailAdvertiserViewLink>
      .يمكنك تسليط الضوء على منتجاتك وزيادة مبيعاتك{" "}
      <b>الإعلان الممول للمنتجات،</b> باستخدام. للبدء
    </RetailText>,
  ];

  const dataForCampaignType = () => {
    switch (i18n.language) {
      case "tr":
        return dataForCampaignTypeTr;
      case "en":
        return dataForCampaignTypeEn;
      case "ar":
        return dataForCampaignTypeAr;
      default:
        return dataForCampaignTypeEn;
    }
  };

  const length =
    type === "create" ? dataForCreateType.length : dataForCampaignType.length;

  const prev = () => {
    if (visible === 0) return;
    setVisible(visible - 1);
  };

  const next = () => {
    visible === length - 1 ? setVisible(0) : setVisible(visible + 1);
  };

  const infoBarClassName = () => {
    if (showAdminBar && showDocs) return cm.advertiserViewWithDocs;

    if (showAdminBar) return cm.advertiserView;
  };

  return (
    <Row
      className={cc([
        "flex",
        cm.container,
        type === "create" ? cm.blueContainer : cm.yellowContainer,
        infoBarClassName(),
      ])}
    >
      <Col>
        <BulbFilled className={cm[type]} />
      </Col>
      <Col className={cm.textContainer}>
        {type === "create"
          ? dataForCreateType[visible]
          : dataForCampaignType()[visible]}
      </Col>
      <Col className={cc(["flex"])}>
        <Col className={cc(["flex", cm.iconContainer])}>
          <LeftOutlined onClick={prev} className={cc([cm.icon, cm[type]])} />
          <RightOutlined onClick={next} className={cc([cm.icon, cm[type]])} />
        </Col>
        <Col className="flex">
          <DotFilled className={cm[type]} />
        </Col>
        <Col
          className={cc([
            cm[type],
            type === "create" ? cm.infoTextCreate : cm.infoTextCampaign,
          ])}
        >
          <RetailText weight="bold">{`${visible + 1} / ${length}`}</RetailText>
        </Col>
      </Col>
    </Row>
  );
};

export default RetailInfoBar;
