import {
	Col,
	Form,
	Radio,
	Switch,
} from "antd";
import cc from "classcat";
import { useTranslation } from "react-i18next";

import { ReactComponent as WarningBlueFilled } from "../../../../../assets/icons/blockFilled.svg";
import { marketplaceName } from "../../../../../utils/defaults";
import SelectProductsTable from "../../../../Table/SelectProductsTable";
import RetailSuggestedTag from "../../../../Tag/RetailSuggestedTag";
import RetailText from "../../../../Typography/RetailText";
import RetailTitle from "../../../../Typography/RetailTitle";
import RetailFormInfo from "../../../RetailFormInfo";
import RetailFormRow from "../../../RetailFormRow";
import cm from "../../style.module.scss";

interface ConnectedWithProductsProps {
  targeting: string;
  connected_with_products: boolean;
  promote_product: boolean;
  handleFieldChange(e: any): void;
}

const ConnectedWithProducts = ({
  targeting,
  connected_with_products,
  promote_product,
  handleFieldChange,
}: ConnectedWithProductsProps) => {
  const { t } = useTranslation();
  return (
    <RetailFormRow className="form-row">
      <RetailFormInfo
        column={{
          number: targeting === "AUTO_TARGETING" ? "6" : "7",
          title: t("components.campaignForm.firstStep.connectedProductsTitle"),
          text: t("components.campaignForm.firstStep.connectedProductsText"),
        }}
      />
      <Form.Item name="connected_with_products">
        <Radio.Group
          name="connected_with_products"
          className={cm.placement}
          onChange={handleFieldChange}
        >
          <Radio
            value={true}
            className={cc(["form-radio", cm.placementCheckbox])}
          >
            <RetailTitle level={5} className={cc(["flex", cm.marginBottom])}>
              {t("components.campaignForm.firstStep.showProducts")}
              <RetailSuggestedTag className={cm.suggestedTag} />
            </RetailTitle>
            <RetailText className={cm.radioText} family="poppins">
              {t("components.campaignForm.firstStep.showProductsText")}
            </RetailText>
            {connected_with_products && marketplaceName !== "koçtaş" && (
              <section className={cm.connectedInfo}>
                <div className={cm.connectedTitleContainer}>
                  <RetailText size="xs">
                    <strong>
                      {t("components.campaignForm.firstStep.promoteProduct")}
                    </strong>
                  </RetailText>
                  <Switch
                    checked={promote_product}
                    onChange={(checked) =>
                      handleFieldChange({
                        target: { name: "promote_product", value: checked },
                      })
                    }
                    size="small"
                  />
                </div>
                <RetailText
                  size="xxxs"
                  weight="medium"
                  className={cm.connectedText}
                >
                  {t("components.campaignForm.firstStep.promoteProductText")}
                </RetailText>
                <div className={cm.connectedWarning}>
                  <WarningBlueFilled className={cm.warningIcon} />
                  <RetailText
                    size="xxxs"
                    weight="medium"
                    className={cm.connectedWarningText}
                  >
                    {t(
                      "components.campaignForm.firstStep.promoteProductWarning"
                    )}
                  </RetailText>
                </div>
              </section>
            )}
          </Radio>

          <Radio
            value={false}
            className={cc(["form-radio", cm.placementCheckbox])}
          >
            <RetailTitle level={5} className={cm.marginBottom}>
              {t("components.campaignForm.firstStep.closeProducts")}
            </RetailTitle>
            <RetailText className={cm.radioText} family="poppins">
              {t("components.campaignForm.firstStep.closeProductsText")}
            </RetailText>
          </Radio>
        </Radio.Group>
      </Form.Item>
      {connected_with_products && (
        <Col span={24}>
          <SelectProductsTable
            ad_type="PRODUCT"
            fetchAll={false}
            error={false}
          />
        </Col>
      )}
    </RetailFormRow>
  );
};

export default ConnectedWithProducts;
