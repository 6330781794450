import { useTranslation } from "react-i18next";
import { switchFlag } from "../../../utils/helpers";
import { ReactComponent as CopyOutlined } from "../../../assets/icons/copyOutlined.svg";
import { ReactComponent as DotFilled } from "../../../assets/icons/dotFilled.svg";
import RetailTitle from "../../Typography/RetailTitle";
import VastPlayer from "../../Player/VastPlayer";
import RetailText from "../../Typography/RetailText";
import RetailLinkColumn from "../../Column/RetailLinkColumn";
import RetailMainButton from "../../Button/RetailMainButton";
import RetailNameColumn from "../../Column/RetailNameColumn";

import cc from "classcat";
import cm from "./style.module.scss";

interface RetailCreativeInspectCardProps {
  record: any;
  index: number;
  current: number;
  marketplaceHasLanguage: boolean;
  changeValue: (record: any, value: string) => void;
  handleReject: (record: any) => void;
}

const RetailCreativeInspectCard = ({
  record,
  index,
  current,
  marketplaceHasLanguage,
  changeValue,
  handleReject,
}: RetailCreativeInspectCardProps) => {
  const { t } = useTranslation();

  const copyTextToClipboard = (item: string) =>
    navigator.clipboard.writeText(item);

  const {
    id,
    creative_name,
    format,
    vast_tag,
    image_url,
    campaign_id,
    campaign_name,
    advertiser_id,
    advertiser_name,
    redirect_url,
    size,
    policy_status,
    languages,
    categories,
    keywords,
  } = record;

  const creativeHasLanguage = marketplaceHasLanguage && languages?.length > 0;

  const creativeHasTargeting = categories || keywords;

  return (
    <div className={cc([cm.card, index === current ? cm.visible : ""])}>
      {/*
       * Creative Name Row
       */}
      <RetailTitle level={5} className={cm.title}>
        {creative_name}
      </RetailTitle>
      {/*
       * The actual image or video
       */}
      <div className={cm.imgContainer}>
        {format === "VIDEO" ? (
          <VastPlayer vastURL={vast_tag || ""} type="inspect" id={id} />
        ) : (
          <img src={image_url} alt={creative_name} />
        )}
      </div>
      {/*
       * Vast Tag Row
       */}
      {vast_tag && (
        <div className={cm.vastTag}>
          <RetailText weight="medium" size="xs" className={cm.vastTagText}>
            {t("pages.admin.inspect.vastTag")}
          </RetailText>
          <a
            href={vast_tag}
            target="_blank"
            rel="noreferrer"
            className={cc([cm.link, cm.vastTagLink])}
          >
            {vast_tag}
          </a>
        </div>
      )}
      {/*
       * Campaign and Advertiser Row
       */}
      <div className={cm.text}>
        <div>
          <RetailText
            family="poppins"
            weight="medium"
            size="xs"
            className={cm.grayText}
          >
            {t("pages.admin.inspect.campaign")}
          </RetailText>
          <RetailLinkColumn
            to={`/campaign/${campaign_id}?adv=${advertiser_id}`}
            value={campaign_name}
            state={{ breadcrumb: true, breadcrumbType: "campaign" }}
          />
        </div>
        <div>
          <RetailText
            family="poppins"
            weight="medium"
            size="xs"
            className={cm.grayText}
          >
            {t("pages.admin.inspect.advertiser")}
          </RetailText>
          <RetailNameColumn
            to={`/campaigns?adv=${advertiser_id}`}
            value={`${advertiser_name || "-"} (${advertiser_id})`}
          />
        </div>
      </div>
      {/*
       * Redirect URL Row
       */}
      <div className={cc(["flex", cm.urlContainer])}>
        <RetailText
          family="poppins"
          weight="medium"
          size="xs"
          className={cm.grayText}
        >
          {t("pages.admin.inspect.url")}
        </RetailText>
        <a
          href={redirect_url}
          className={cm.link}
          target="_blank"
          rel="noreferrer"
        >
          {redirect_url}
        </a>
        <CopyOutlined onClick={() => copyTextToClipboard(redirect_url)} />
      </div>
      {/*
       * Size, Status, and Languages Row
       */}
      <div className={cc(["flex", cm.sizeContainer])}>
        <div className="flex">
          <RetailText
            family="poppins"
            weight="medium"
            size="xs"
            className={cm.grayText}
          >
            {t("pages.admin.inspect.size")}
          </RetailText>
          <RetailText family="poppins" weight="medium" size="xs">
            {size}
          </RetailText>
        </div>

        <DotFilled />

        <div className="flex">
          <RetailText
            family="poppins"
            weight="medium"
            size="xs"
            className={cm.grayText}
          >
            {t("pages.admin.inspect.status")}
          </RetailText>
          <RetailText
            family="poppins"
            weight="medium"
            size="xs"
            className={policy_status ? cm[policy_status.toLowerCase()] : ""}
          >
            {policy_status
              ? t(`pages.admin.creatives.${policy_status.toLowerCase()}`)
              : "-"}
          </RetailText>
        </div>
        <DotFilled />
        {creativeHasLanguage && (
          <div className={cc(["flex", cm.languageWrapper])}>
            <RetailText
              family="poppins"
              weight="medium"
              size="xs"
              className={cm.grayText}
            >
              {t("pages.admin.inspect.languages")}
            </RetailText>
            {languages?.map((lang: string) => (
              <RetailText
                family="poppins"
                weight="medium"
                size="xxxs"
                className={cm.languageContainer}
                key={lang}
              >
                {t(`common.languages.${lang?.toLowerCase()}`)}
                <span>{switchFlag(lang)}</span>
              </RetailText>
            ))}
          </div>
        )}
      </div>
      {/*
       *  Categories and Keywords Row
       */}
      {creativeHasTargeting && (
        <div className={cc(["flex", cm.infoContainer])}>
          <RetailText
            family="poppins"
            weight="medium"
            size="xs"
            className={cm.grayText}
          >
            {categories
              ? t("pages.admin.inspect.categories")
              : t("pages.admin.inspect.keywords")}
          </RetailText>
          {categories?.map((item: string) => (
            <RetailText family="poppins" weight="medium" size="xs" key={item}>
              {item}
            </RetailText>
          )) ||
            keywords?.map((item: string) => (
              <RetailText family="poppins" weight="medium" size="xs" key={item}>
                {item}
              </RetailText>
            ))}
        </div>
      )}
      {/*
       * Actions Row
       */}
      <div className={cc(["flex", cm.btnContainer])}>
        <RetailMainButton
          hasBackground={false}
          className={cc(["flex", cm.btn, cm.rejectBtn])}
          onClick={() => handleReject(record)}
        >
          {t("common.reject")}
        </RetailMainButton>

        <RetailMainButton
          hasBackground
          className={cc(["flex", cm.btn, cm.okBtn])}
          onClick={() => changeValue(record, "ACCEPTED")}
        >
          {t("common.accept")}
        </RetailMainButton>
      </div>
    </div>
  );
};

export default RetailCreativeInspectCard;
