import Menu from "antd/lib/menu";
import SubMenu from "antd/lib/menu/SubMenu";
import cc from "classcat";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SMOContext, SMOContextType } from "../../../../context/SMOProvider";
import { retailAdminRouteObject } from "./RetailAdminRouteObject";

import cm from "../style.module.scss";

const RetailAdminSider = () => {
  const { t } = useTranslation();

  const { showDocs } = useContext(SMOContext) as SMOContextType;

  const [selectedKey, setSelectedKey] = useState<string>("/admin/campaigns");

  const [openKeys, setOpenKeys] = useState<string>("");

  const location = useLocation();

  const navigate = useNavigate();

  const submenuItemsCheck = (route: any) =>
    route.children
      ?.map((child: any) => child.link)
      .toString()!
      .includes(selectedKey);

  useEffect(() => {
    if (location.pathname !== selectedKey) {
      setSelectedKey(
        location.pathname.includes("campaign")
          ? "/admin/campaigns"
          : location.pathname
      );
    }
    if (location.pathname.includes("/report/")) {
      setSelectedKey("/admin/reports");
    }
  }, [location, selectedKey]);

  const regex = /\d/.test(decodeURI(location.pathname));

  const handleOpenKeys = (item: any) => {
    if (openKeys === item.key) setOpenKeys("");
    else setOpenKeys(item.key);
  };

  const clearOpenKeys = () => setOpenKeys("");

  const handleDetailsPage = () => {
    navigate(selectedKey);
    clearOpenKeys();
  };

  return (
    <Menu
      mode="inline"
      defaultSelectedKeys={[selectedKey]}
      selectedKeys={[selectedKey]}
      openKeys={[openKeys]}
      theme="light"
      className={cc([cm.menu, showDocs ? cm.justDocs : ""])}
    >
      {retailAdminRouteObject.map((route) =>
        route.sub ? (
          <SubMenu
            title={t(`sider.admin.${route.key}`)}
            key={route.key}
            className={cc([cm.subMenu, cm.admin])}
            icon={
              submenuItemsCheck(route) ? route.iconFilled : route.iconOutlined
            }
            onTitleClick={handleOpenKeys}
          >
            {route.children?.map((child) => (
              <Menu.Item
                key={child.link}
                className={cc([
                  cm.listItem,
                  selectedKey === child.link ? cm.selected : "",
                ])}
              >
                <Link
                  to={child.link}
                  state={{
                    breadcrumb: route.breadcrumb,
                    breadcrumbType: route.key,
                    value: child.key,
                  }}
                >
                  {t(`sider.admin.${child.key}`)}
                </Link>
              </Menu.Item>
            ))}
          </SubMenu>
        ) : (
          <Menu.Item
            key={route.link}
            className={cc([
              cm.listItem,
              cm.admin,
              selectedKey === route.link ? cm.selected : "",
            ])}
            onClick={
              regex && route.link === selectedKey
                ? handleDetailsPage
                : clearOpenKeys
            }
          >
            <Link to={route.link!}>
              {selectedKey === route.link
                ? route.iconFilled
                : route.iconOutlined}
              <span>{t(`sider.admin.${route.key}`)}</span>
            </Link>
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

export default RetailAdminSider;
